<template>
  <div class="">
    <div class="row">
      <div
        v-if="content.title"
        class="col-12 pl-2"
        style="white-space: pre-line;">
        <h6>{{content.title}}</h6>
      </div>

      <div
        v-if="content.content"
        class="col-12 mt-2 pl-4"
        style="white-space: pre-line;">
        {{content.content}}
      </div>

      <UserManualContentTable
        v-if="content.tableContent"
        class="col-12 mt-2"
        :value="content.tableContent">
      </UserManualContentTable>

      <UserManualContentImage
        v-if="content.imageContent"
        class="col-12 mt-2"
        :src="content.imageContentPreview"
        :value="content.imageContent">
      </UserManualContentImage>
    </div>

    <div class="row mx-2" v-if="level < 3">
      <div class="col-12">
        <UserManualContent
          class=""
          v-for="child in content.children"
          :key="child.id"
          :level="level+1"
          :paperId="paperId"
          :content="child">
        </UserManualContent>
      </div>
    </div>
  </div>
</template>

<script>
import UserManualContentTable from './UserManualContentTable'
import UserManualContentImage from './UserManualContentImage'

export default {
  name: 'UserManualContent',
  props: {
    paperId: {
      type: Number,
      required: true
    },
    level: {
      type: Number,
      required: true
    },
    content: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      formData: {
        title: '',
        content: '',
        imageContent: '',
        tableContent: '',
      }
    }
  },
  watch: {
    content: {
      handler (v) {
        this.formData.title = v.title
        this.formData.content = v.content
        this.formData.imageContent = v.imageContent
        this.formData.tableContent = v.tableContent
      },
      immediate: true
    }
  },
  components: {
    UserManualContentTable,
    UserManualContentImage
  }
}
</script>

<style lang="css" scoped>
</style>
