import gql from 'graphql-tag'


const listResponse = `
  id type code name
  contactId contact {id type code name branch}
  createdAt createdBy createdUser {id name}
  approvedAt approvedBy approvedUser {id name}
  closedAt closedBy closedUser {id name}
`

export const LIST_DOC = (templateType) => gql`query LIST_DOC ($docType: String!, $q: FilterInput) {
  docs: list${templateType}Doc (docType: $docType, q: $q) {${listResponse}}
}`

const detailResponse = `
  id type code name
  contactId contact {id type code name branch}
  createdAt createdBy createdUser {id name}
  approvedAt approvedBy approvedUser {id name}
  closedAt closedBy closedUser {id name}
  ledgerBalances {
    id amount
    accountId account {id code name}
  }
`

export const DETAIL_DOC = (templateType) => gql`query DOC ($docType: String!, $docId: Int!) {
  doc: detail${templateType}Doc (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const CREATE_DOC = (templateType) => gql`mutation CREATE_DOC ($docType: String!, $input: ${templateType}Input!, $closedAt: DateTime!) {
  doc: create${templateType}Doc (docType: $docType, input: $input, closedAt: $closedAt) {id}
}`

export const DESTROY_DOC = (templateType) => gql`mutation DESTROY_DOC ($docType: String!, $docId: Int!) {
  doc: destroy${templateType}Doc (docType: $docType, docId: $docId) {id}
}`