<template>
  <div class="text-center">
    <img
      :src="src"
      :alt="imageName"
      class="img-fluid rounded">
    <div class="mt-1">
      <i>{{imageName}}</i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserManualContentImage',
  props: {
    value: {
      type: String,
      required: true
    },
    src: {
      type: String,
      required: true
    }
  },
  computed: {
    imageName () {
      const namePatt = /^!\[(.*?)\]/
      const imgName = this.value.match(namePatt)
      return imgName ? imgName[1] : ''
    }
  }
}
</script>

<style lang="css" scoped>
</style>
